import { graphql, PageProps } from "gatsby"
import React from "react"
import BaseComponent from "../components/page/base"
import { TemplateVacatureQuery } from "../__generated__/graphql-gatsby"

type IProps = PageProps<TemplateVacatureQuery>

const VacaturePage: React.FC<IProps> = ({ data: { wpVacature } }) => {
  const key = String(wpVacature?.uri)

  return <BaseComponent data={wpVacature} sessionKey={key} />
}

export default VacaturePage

export const query = graphql`
  fragment PageBuilderVacatureCTA on WpVacature_PageBuilder_PageBuilder_Cta {
    __typename
    titel
    subtitel
    uitlijningTitel
    linkKnop {
      title
      url
      target
    }
    uitlijningKnop
  }
  fragment PageBuilderVacatureImage on WpVacature_PageBuilder_PageBuilder_Afbeelding {
    __typename
    maxWidth
    afbeelding {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  fragment PageBuilderVacatureText on WpVacature_PageBuilder_PageBuilder_TekstEditor {
    __typename
    instellingen {
      achtergrond
      breedte
    }
    tekst
  }
  fragment PageBuilderVacatureSpacer on WpVacature_PageBuilder_PageBuilder_Spacer {
    __typename
    hoogte
  }
  fragment PageBuilderVacatureFaq on WpVacature_PageBuilder_PageBuilder_Faq {
    __typename
    titel
    tekst
    kleur
    standaardGesloten
    metaData
  }
  fragment PageBuilderVacatureUitgelichteActiviteit on WpVacature_PageBuilder_PageBuilder_UitgelichteActiviteit {
    __typename
    flip
    onlyImg
    activiteit {
      ...UitgelichteActiviteit
    }
  }
  fragment PageBuilderVacatureUitgelichteErvaring on WpVacature_PageBuilder_PageBuilder_UitgelichteErvaring {
    __typename
    flip
    onlyImg
    ervaring {
      ...UitgelichteErvaring
    }
  }
  fragment PageBuilderVacatureLaatsteBerichten on WpVacature_PageBuilder_PageBuilder_LaatsteBerichten {
    __typename
    type {
      nodesOfPostTypes {
        nodes(limit: 5) {
          ...LaatsteBerichtenData
        }
      }
    }
    aantal
  }
  fragment PageBuilderVacatureAgenda on WpVacature_PageBuilder_PageBuilder_Agenda {
    __typename
    weergave
    tags {
      id
    }
  }
  fragment PageBuilderVacatureForm on WpVacature_PageBuilder_PageBuilder_Formulier {
    __typename
    nieuwsbriefFormulier
    addtext
    text
    redirect {
      url
    }
    form {
      ...Form
    }
  }
  fragment PageBuilderVacatureOverzicht on WpVacature_PageBuilder_PageBuilder_OverzichtPaginas {
    __typename
    format
    sortering
    tags {
      id
    }
  }
  fragment PageBuilderVacatureYoutube on WpVacature_PageBuilder_PageBuilder_Youtube {
    __typename
    titel
    toonTitel
    youtubeId
    aspectRatio
    tekst
    metTekst {
      plaatsTekst
      achtergrond
      breedteContainer
    }
  }
  fragment PageBuilderVacatureFacebook on WpVacature_PageBuilder_PageBuilder_Facebook {
    __typename
    tabs
  }
  fragment PageBuilderVacatureButtonsGroup on WpVacature_PageBuilder_PageBuilder_ButtonsGroup {
    __typename
    buttons {
      button {
        url
        title
        target
      }
    }
  }
  fragment PageBuilderVacatureBlocks on WpVacature_PageBuilder_PageBuilder {
    ... on WpVacature_PageBuilder_PageBuilder_TekstEditor {
      ...PageBuilderVacatureText
    }
    ... on WpVacature_PageBuilder_PageBuilder_Spacer {
      ...PageBuilderVacatureSpacer
    }
    ... on WpVacature_PageBuilder_PageBuilder_Afbeelding {
      ...PageBuilderVacatureImage
    }
    ... on WpVacature_PageBuilder_PageBuilder_Faq {
      ...PageBuilderVacatureFaq
    }
    ... on WpVacature_PageBuilder_PageBuilder_Cta {
      ...PageBuilderVacatureCTA
    }
    ... on WpVacature_PageBuilder_PageBuilder_UitgelichteActiviteit {
      ...PageBuilderVacatureUitgelichteActiviteit
    }
    ... on WpVacature_PageBuilder_PageBuilder_UitgelichteErvaring {
      ...PageBuilderVacatureUitgelichteErvaring
    }
    ... on WpVacature_PageBuilder_PageBuilder_LaatsteBerichten {
      ...PageBuilderVacatureLaatsteBerichten
    }
    ... on WpVacature_PageBuilder_PageBuilder_OverzichtPaginas {
      ...PageBuilderVacatureOverzicht
    }
    ... on WpVacature_PageBuilder_PageBuilder_Agenda {
      ...PageBuilderVacatureAgenda
    }
    ... on WpVacature_PageBuilder_PageBuilder_Formulier {
      ...PageBuilderVacatureForm
    }
    ... on WpVacature_PageBuilder_PageBuilder_Youtube {
      ...PageBuilderVacatureYoutube
    }
    ... on WpVacature_PageBuilder_PageBuilder_Facebook {
      ...PageBuilderVacatureFacebook
    }
    ... on WpVacature_PageBuilder_PageBuilder_ButtonsGroup {
      ...PageBuilderVacatureButtonsGroup
    }
  }
  fragment PageBuilderVacature on WpVacature_PageBuilder {
    pageBuilder {
      ...PageBuilderVacatureBlocks
    }
  }
  fragment wpVacature on WpVacature {
    id
    uri
    seo {
      ...SEO
    }
    page_builder {
      ...PageBuilderVacature
    }
  }
  query TemplateVacature($id: String!) {
    wpVacature(id: { eq: $id }) {
      ...wpVacature
    }
  }
`
